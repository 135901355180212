import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./viewes/Home";
import IdealBalanceTest from "./tests/IdealBalanceTest";
import IdealBalanceTestResult from "./results/IdealBalanceTestResult";
import GlobalStyle from "./common/css/globalStyle";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/ideal-balance-test">
          <IdealBalanceTest />
        </Route>
        <Route>
          <Route
            path="/ideal-balance-test/result/:type"
            component={IdealBalanceTestResult}
          />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
