import S from "./style.module.scss";
import { YOUTUBE_URL, INSTAGRAM_URL, BLOG_URL } from "../../common/constants";

const Home = () => {
  const goToTest = (path) => {
    window.location.href = "/" + path;
  };

  const openURL = (url, type) => {
    type === "blank" ? window.open(url, "_blank") : window.open(url, "_self");
  };

  return (
    <>
      <div className={S.home_wrapper}>
        <img src="/img/logo_black.png" alt="logo" className={S.logo_img} />
        <div className={S.sns_container}>
          <img
            src="/img/youtube_logo.png"
            alt="youtube_logo"
            className={S.sns_item}
            onClick={() => openURL(YOUTUBE_URL, "blank")}
          />
          <img
            src="/img/instagram_logo.png"
            alt="instagram_logo"
            className={S.sns_item}
            onClick={() => openURL(INSTAGRAM_URL, "blank")}
          />
          <img
            src="/img/blog_logo.png"
            alt="blog_logo"
            className={S.sns_item}
            onClick={() => openURL(BLOG_URL, "blank")}
          />
        </div>
        <div className={S.content_wrapper}>
          <div
            className={S.test_btn}
            onClick={() => goToTest("ideal-balance-test")}
          >
            이상형 MBTI 테스트
          </div>
          <div
            className={S.test_btn}
            onClick={() => openURL("https://eitest.mindmingle.kr", "self")}
          >
            외향(E)-내향(I) 테스트
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
