import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import S from "./style.module.scss";
import Questions from "../../common/api/questions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KakaoShare from "../../components/KakaoShare";
import TwitterShare from "../../components/TwitterShare";
import {
  url,
  ideal_balance_test,
  kakao_desc,
  twitter_desc,
  cover_url,
} from "../../common/constants";

const IdealBalanceTest = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [result, setResult] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [showOptions, setOptions] = useState(false);
  const history = useHistory();
  const currentQuestion = Questions[currentQuestionIndex];

  const copyAlert = () => {
    alert("링크 복사 완료!");
  };

  const goToOptions = () => {
    setOptions(true);
  };

  const makeResult = (select_result) => {
    setShowResults(true);

    setTimeout(() => {
      history.push(`/ideal-balance-test/result/type_${select_result}`);
    }, 2000);
  };

  const handleSelectClick = (selectType) => {
    setResult((prevResult) => {
      const updatedResult = prevResult + selectType;

      if (currentQuestionIndex < Questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        makeResult(updatedResult);
      }

      return updatedResult;
    });
  };

  return (
    <>
      {!showOptions ? (
        <div className={S.home_wrapper}>
          <div className={S.content_wrapper}>
            <div className={S.deco_wrapper}>5초만에 끝나는</div>
            <div className={S.title_wrapper}>
              <div className={S.title_txt1}>이상형 MBTI</div>
              <div className={S.title_txt2}>밸런스 게임</div>
            </div>
            <div className={S.start_btn_wrapper} onClick={goToOptions}>
              START
            </div>
            <div className={S.share_txt}>테스트 공유하기</div>
            <div className={S.share_wrapper}>
              <KakaoShare desc={kakao_desc} imgURL={cover_url} />
              <TwitterShare name={ideal_balance_test} desc={twitter_desc} />
              <div className={S.share_item} onClick={copyAlert}>
                <CopyToClipboard text={url + `${ideal_balance_test}`}>
                  <img
                    className={S.share_img}
                    src="/img/copy_link.png"
                    alt="img"
                  />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={S.options_wrapper}>
          {!showResults ? (
            currentQuestion ? (
              <>
                <div className={S.title_container}>
                  <div className={S.title_txt}>밸런스 게임</div>
                  <div className={S.title_num_txt}>
                    0{currentQuestionIndex + 1}
                  </div>
                </div>
                <div className={S.select_box_container}>
                  <div
                    className={S.select_box}
                    onClick={() =>
                      handleSelectClick(currentQuestion.questions[0].type)
                    }
                  >
                    <div
                      className={`${
                        S["box_option_" + currentQuestion.questions[0].type]
                      }`}
                    >
                      <div className={S.box_txt}>
                        {currentQuestion.questions[0].question}
                      </div>
                    </div>
                    <div className={S.box_button_1}>
                      {currentQuestion.questions[0].type}
                    </div>
                  </div>
                  <div
                    className={S.select_box}
                    onClick={() =>
                      handleSelectClick(currentQuestion.questions[1].type)
                    }
                  >
                    <div
                      className={`${
                        S["box_option_" + currentQuestion.questions[1].type]
                      }`}
                    >
                      <div className={S.box_txt}>
                        {currentQuestion.questions[1].question}
                      </div>
                    </div>
                    <div className={S.box_button_2}>
                      {currentQuestion.questions[1].type}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <p>Loading...</p>
            )
          ) : (
            <div className={S.loading_wrapper}>
              <img
                className={S.checking_img}
                src="img/checking.png"
                alt="checking-mingle"
              />
              <div className={S.loading}></div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default IdealBalanceTest;
