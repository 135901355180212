import Result from "../../common/api/result";
import S from "./style.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KakaoShare from "../../components/KakaoShare";
import TwitterShare from "../../components/TwitterShare";
import { cover_url, url, ideal_balance_test } from "../../common/constants";

const EXPERT_URL =
  "https://m.expert.naver.com/expert/profile/home?storeId=100043222";
const YOUTUBE_URL = "https://www.youtube.com/@mind_mingle";
const INSTAGRAM_URL = "https://www.instagram.com/mbti_mind_mingle/";
const IDLE_TEST_VIDEO_URL = "https://www.youtube.com/watch?v=pM83JUEeWDo";

const IdealBalanceTestResult = ({ match }) => {
  const { type } = match.params;
  const result = Result[type];
  const result_type = type.split("_")[1];

  const goToHome = () => {
    window.location.href = "/";
  };

  const openURL = (url) => {
    window.open(url, "_blank");
  };

  if (!result) {
    return (
      <div className={S.empty_container}>
        <div className={S.empty_txt}>존재하지 않는 결과입니다.</div>
        <div className={S.return_btn} onClick={goToHome}>
          돌아가기
        </div>
      </div>
    );
  }
  const copyAlert = () => {
    alert("링크 복사완료!");
  };
  return (
    <div className={S.results_wrapper}>
      <div className={S.content_wrapper}>
        <div className={S.title_txt}>
          당신의 이상형 <br />
          MBTI는..?
        </div>
        <img
          src={`/img/mingle_${result_type}.png`}
          className={S.mingle_type_img}
        />
        <div className={S.result_container}>
          <div className={S.result_txt}>{result_type}</div>
          <div className={S.result_txt}>{result.title}</div>
          <div className={S.mbti_grid}>
            {" "}
            {result.mbtis.map((mbti, index) => (
              <div key={index} className={S.mbti_item}>
                {" "}
                {mbti}
              </div>
            ))}
          </div>
        </div>
        <div
          className={S.youtube_cta_btn}
          onClick={() => openURL(IDLE_TEST_VIDEO_URL)}
        >
          유튜브 설명 보러가기
        </div>
        <img src="/img/divider.png" alt="divider" className={S.divider_img} />
        <img className={S.phrase_img} src="/img/phrase.png" alt="img" />
        <img className={S.logo_next_img} src="/img/logo_next.png" alt="img" />
        <div className={S.btns_wrapper}>
          <div className={S.desc_txt}>
            전체 선호지표 클리어/라이트/블러 세부 척도 확인 가능
          </div>
          <div className={S.btn_wrapper} onClick={() => openURL(EXPERT_URL)}>
            정식검사 하러가기
          </div>
          <div className={S.btn_wrapper} onClick={() => openURL(YOUTUBE_URL)}>
            마인드밍글 유튜브 채널 보러가기
          </div>
          <div className={S.btn_wrapper} onClick={() => openURL(INSTAGRAM_URL)}>
            마인드밍글 인스타그램
          </div>
          <img
            className={S.twinkle_mingle_img}
            src="/img/twinkle_mingle.png"
            alt="img"
          />
        </div>
        <div className={S.share_txt}>내 결과 공유하기</div>
        <div className={S.share_wrapper}>
          <KakaoShare
            desc={`나는 ${result_type} "${result.title}"`}
            imgURL={cover_url}
          />
          <TwitterShare
            name={ideal_balance_test}
            desc={`나는 ${result_type} "${result.title}"

#mbti #mbti테스트 #외향내향테스트`}
            type={`type_${result_type}`}
          />
          <div className={S.share_item} onClick={copyAlert}>
            <CopyToClipboard
              text={
                url +
                `${ideal_balance_test}` +
                "/result/" +
                `type_${result_type}`
              }
            >
              <img className={S.share_img} src="/img/copy_link.png" alt="img" />
            </CopyToClipboard>
          </div>
        </div>

        <div
          style={{ margin: "3rem 0" }}
          className={S.btn_wrapper}
          onClick={goToHome}
        >
          테스트 다시 하기 🔄
        </div>
      </div>
    </div>
  );
};

export default IdealBalanceTestResult;
