export const EXPERT_URL =
  "https://m.expert.naver.com/expert/profile/home?storeId=100043222";
export const YOUTUBE_URL = "https://www.youtube.com/@mind_mingle";
export const INSTAGRAM_URL = "https://www.instagram.com/mbti_mind_mingle/";
export const BLOG_URL = "https://blog.naver.com/mind-mingle";

export const s3_baseURL =
  "https://mindmingle-assets.s3.ap-northeast-2.amazonaws.com/idle-balance-test/";
export const url = "https://test.mindmingle.kr/";
export const cover_url = s3_baseURL + "cover.png";

export const ideal_balance_test = "ideal-balance-test";
export const kakao_desc = `너의 이상형 MBTI를 확인해봐!`;
export const twitter_desc = `5초만에 끝나는 이상형 MBTI 밸런스 게임
    
#mbti #mbti테스트 #이상형 #밸런스게임`;

export const kakao_share_title = `5초만에 끝나는 이상형 MBTI 밸런스 게임`;
